import type { $Fetch } from 'nitropack'
import type { paths } from '~/interfaces/api/api'

type GetBannersOperation = paths['/banners']['get']
type GetBannersOperationOperationResponseBody = GetBannersOperation['responses']['200']['content']['application/json']

export interface BannerRepository {
  getBanners: (scope: string) => Promise<GetBannersOperationOperationResponseBody>
}

export function bannerRepository(fetch: $Fetch) {
  return ({
    async getBanners(scope): Promise<GetBannersOperationOperationResponseBody> {
      return await fetch(`/banners?scope=${scope}`)
    },
  }) as BannerRepository
}
