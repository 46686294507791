export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, minimum-scale=1.0"},{"name":"apple-mobile-web-app-title","content":"BeyondBreath"},{"name":"application-name","content":"BeyondBreath"},{"name":"msapplication-TileColor","content":"#ffc40d"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"apple-touch-icon","href":"/favicon/apple-touch-icon.png"},{"rel":"icon","type":"image/png","href":"/favicon/favicon-32x32.png"},{"rel":"icon","type":"image/png","href":"/favicon/favicon-16x16.png"},{"rel":"manifest","type":"image/png","href":"/favicon/site.webmanifest","crossorigin":"use-credentials"},{"rel":"mask-icon","href":"/favicon/safari-pinned-tab.svg","color":"#1f1f1f"}],"style":[],"script":[{"src":"https://js.stripe.com/v3/","async":true}],"noscript":[],"title":"BeyondBreath"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"