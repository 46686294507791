import type { CookieOptions } from 'nuxt/app'

export function useAnalytics() {
  const route = useRoute()

  const campaignParams = [
    'utm_medium',
    'utm_source',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'adset_id',
    'ad_id',
    'placement',
    'campaign_id',
  ]

  const campaignData: Record<string, string | null> = {}

  const getCookieOptions = (): CookieOptions => {
    const currentDomain = useRequestURL().hostname.replace(/^www\./, '')
    return {
      domain: currentDomain,
      maxAge: 60 * 60 * 24 * 365, // 1 year
      secure: true,
      sameSite: 'strict',
    }
  }

  const getParamValue = (param: string): string | null => {
    try {
      const cookieOptions = getCookieOptions()

      // Initialize campaignData if it's empty
      if (Object.keys(campaignData).length === 0) {
        // Check if any campaign parameter is present in the URL
        const hasCampaignParam = campaignParams.some(p => route.query[p])

        if (hasCampaignParam) {
          // If any campaign param is present, use URL parameters
          campaignParams.forEach((p) => {
            campaignData[p] = route.query[p] as string || null
          })

          // Save to cookies
          Object.entries(campaignData).forEach(([key, value]) => {
            const cookie = useCookie(key, cookieOptions)
            cookie.value = value
          })
        }
        else {
          // If no campaign params in URL, load from cookies
          campaignParams.forEach((p) => {
            campaignData[p] = useCookie(p, cookieOptions).value || null
          })
        }
      }

      // For non-campaign params
      if (!campaignParams.includes(param)) {
        if (route.query[param]) {
          const cookie = useCookie(param, cookieOptions)
          cookie.value = route.query[param] as string
          return route.query[param] as string
        }
        else {
          return useCookie(param, cookieOptions).value
        }
      }

      // Return campaign param from campaignData
      return campaignData[param] || null
    }
    catch (error) {
      console.error(`Error in getParamValue for param ${param}:`, error)
      return null
    }
  }

  const setParamValue = (param: string, value: string | null): void => {
    try {
      const cookieOptions = getCookieOptions()
      const cookie = useCookie(param, cookieOptions)
      cookie.value = value
    }
    catch (error) {
      console.error(`Error in setParamValue for param ${param}:`, error)
    }
  }

  return {
    getParamValue,
    setParamValue,
  }
}
