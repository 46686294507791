import type { FetchOptions } from 'ofetch'

interface Repositories {
  access: AccessRepository
  auth: AuthRepository
  banner: BannerRepository
  bundle: BundleRepository
  course: CourseRepository
  client: ClientRepository
  liveCall: LiveCallRepository
  me: MeRepository
  payment: PaymentRepository
  progress: ProgressRepository
  search: SearchRepository
}

const REQUESTS_WITHOUT_AUTH = [
  '/auth/login',
  '/auth/register',
  '/auth/login_apple',
  '/auth/login_google',
  '/auth/refresh',
  '/auth/send_confirmation_instructions',
  '/auth/confirm_account',
  '/auth/send_reset_password_instructions',
  '/auth/reset_password',
]

export default defineNuxtPlugin({
  setup() {
    const authStore = useAuthStore()
    const { isRefreshing, isAuthenticated } = storeToRefs(authStore)

    const api = $fetch.create({
      baseURL: useRuntimeConfig().public.apiUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      async onRequest({ request, options }) {
        // Refresh the token if user isAuthenticated, token it's expired, its not a request
        // that doesn't require authentication and is not already refreshing
        if (isAuthenticated.value
          && authStore.isAuthTokenExpired
          && !isRefreshing.value
          && !REQUESTS_WITHOUT_AUTH.includes(request.toString())) {
          await authStore.refreshAuthToken()
        }

        // Add auth token to the Authorization header if it exists
        if (authStore.authToken && !REQUESTS_WITHOUT_AUTH.includes(request.toString())) {
          options.headers = { Authorization: `Bearer ${authStore.authToken}` }
        }
      },
      async onResponse(context) {
        const { response, options, request } = context

        if (response.status === 401) {
          // Directly handle refresh token failure
          if (request.toString().includes('/refresh')) {
            await authStore.forceLogout()
            return
          }

          // Refresh token and retry the request once if not already refreshing
          if (!isRefreshing.value) {
            // refresh the auth token
            await authStore.refreshAuthToken()

            // Check if the refresh was successful
            if (!authStore.isAuthTokenExpired && authStore.authToken) {
              // Retry the request if the refresh was successful
              await retryRequest(request, options, context)
            }
          }
          else {
            // Wait for the refresh to finish and retry the request
            // an build RetryOptions function is used to build the options
            // for the retry
            await new Promise<void>((resolve) => {
              const interval = setInterval(async () => {
                if (!isRefreshing.value) {
                  clearInterval(interval)
                  if (!authStore.isAuthTokenExpired && authStore.authToken) {
                    await retryRequest(request, options, context)
                  }
                  resolve()
                }
              }, 100)
            })
          }
        }
      },
    })

    async function retryRequest(request: any, options: any, context: any) {
      const retryOptions = buildRetryOptions(options)
      return await $fetch(request, {
        ...retryOptions,
        retry: false,
        onResponse(ctx: any) {
          Object.assign(context, ctx)
        },
      } as any)
    }

    function buildRetryOptions(options: FetchOptions) {
      const headers = new Headers(options.headers)
      headers.set('Authorization', `Bearer ${authStore.authToken}`)
      return {
        baseURL: options.baseURL,
        body: options.body,
        headers,
        method: options.method,
        params: options.params,
        query: options.query,
        responseType: options.responseType,
        ignoreResponseError: options.ignoreResponseError,
        parseResponse: options.parseResponse,
        duplex: options.duplex,
        timeout: options.timeout,
        auth: true,
        retry: false as const,
      }
    }

    const modules: Repositories = {
      access: accessRepository(api),
      auth: authRepository(api),
      banner: bannerRepository(api),
      bundle: bundleRepository(api),
      client: clientRepository(api),
      course: courseRepository(api),
      liveCall: liveCallRepository(api),
      me: meRepository(api),
      payment: paymentRepository(api),
      progress: progressRepository(api),
      search: searchRepository(api),
    }

    return {
      provide: {
        api: modules,
      },
    }
  },
})
