import { AnalyticsBrowser as CioAnalyticsBrowser } from '@customerio/cdp-analytics-browser'

export default defineNuxtPlugin({
  name: 'analytics',
  setup(_nuxtApp) {
    const analytics = new CioAnalyticsBrowser()
    const router = useRouter()
    const { getParamValue } = useAnalytics()
    const delay = 500

    // Function to perform page tracking
    function trackPage(to: any) {
      [analytics].forEach((instance) => {
        instance.page('', to.name || '', {
          path: to.path,
          action_source: 'website',
        }, {
          traits: {
            email: getParamValue('email'),
            first_name: getParamValue('first_name'),
            last_name: getParamValue('last_name'),
            address: {
              country: getParamValue('address.country'),
              city: getParamValue('address.city'),
              postal_code: getParamValue('address.postal_code'),
            },
          },
          campaign: {
            fbp: getParamValue('_fbp'),
            fbc: getParamValue('_fbc'),
            gclid: getParamValue('gclid'),
            wbraid: getParamValue('wbraid'),
            gbraid: getParamValue('gbraid'),
            li_fat_id: getParamValue('li_fat_id'),
            rdt_cid: getParamValue('rdt_cid'),
            twclid: getParamValue('twclid'),
            ad_id: getParamValue('ad_id'),
            adset_id: getParamValue('adset_id'),
            placement: getParamValue('placement'),
            campaign_id: getParamValue('campaign_id'),
            name: getParamValue('utm_campaign'),
            source: getParamValue('utm_source'),
            medium: getParamValue('utm_medium'),
            term: getParamValue('utm_term'),
            content: getParamValue('utm_content'),
          },
        })
      })
    }

    // Track pageviews on each router change
    router.afterEach((to, _from) => {
      setTimeout(() => trackPage(to), delay)
    })

    // Event enrichment and middleware setup remain the same
    const eventEnrichment = {
      name: 'EventEnrichment',
      type: 'enrichment',
      version: '0.0.1',
      load: () => Promise.resolve(),
      isLoaded: () => true,
      track: (ctx: any) => {
        // Get the query parameters from the URL
        const utmMedium = getParamValue('utm_medium')
        const utmSource = getParamValue('utm_source')
        const utmCampaign = getParamValue('utm_campaign')
        const utmContent = getParamValue('utm_content')
        const utmTerm = getParamValue('utm_term')
        const adsetId = getParamValue('adset_id')
        const adId = getParamValue('ad_id')
        const placement = getParamValue('placement')
        const campaignId = getParamValue('campaign_id')
        const fbp = getParamValue('_fbp')
        const fbc = getParamValue('_fbc')
        const gclid = getParamValue('gclid')
        const wbraid = getParamValue('wbraid')
        const gbraid = getParamValue('gbraid')
        const rdt_cid = getParamValue('rdt_cid')
        const li_fat_id = getParamValue('li_fat_id')
        const twclid = getParamValue('twclid')
        const email = getParamValue('email')
        const first_name = getParamValue('first_name')
        const last_name = getParamValue('last_name')
        const city = getParamValue('address.city')
        const country = getParamValue('address.country')
        const postal_code = getParamValue('address.postal_code')

        if (utmMedium !== null) { ctx.updateEvent('context.campaign.medium', utmMedium) }
        if (utmSource !== null) { ctx.updateEvent('context.campaign.source', utmSource) }
        if (utmCampaign !== null) { ctx.updateEvent('context.campaign.name', utmCampaign) }
        if (utmContent !== null) { ctx.updateEvent('context.campaign.content', utmContent) }
        if (utmTerm !== null) { ctx.updateEvent('context.campaign.term', utmTerm) }
        if (adsetId !== null) { ctx.updateEvent('context.campaign.adset_id', adsetId) }
        if (adId !== null) { ctx.updateEvent('context.campaign.ad_id', adId) }
        if (placement !== null) { ctx.updateEvent('context.campaign.placement', placement) }
        if (campaignId !== null) { ctx.updateEvent('context.campaign.campaign_id', campaignId) }
        if (fbp !== null) { ctx.updateEvent('context.campaign.fbp', fbp) }
        if (fbc !== null) { ctx.updateEvent('context.campaign.fbc', fbc) }
        if (gclid !== null) { ctx.updateEvent('context.campaign.gclid', gclid) }
        if (wbraid !== null) { ctx.updateEvent('context.campaign.wbraid', wbraid) }
        if (gbraid !== null) { ctx.updateEvent('context.campaign.gbraid', gbraid) }
        if (rdt_cid !== null) { ctx.updateEvent('context.campaign.rdt_cid', rdt_cid) }
        if (li_fat_id !== null) { ctx.updateEvent('context.campaign.li_fat_id', li_fat_id) }
        if (twclid !== null) { ctx.updateEvent('context.campaign.twclid', twclid) }
        if (email !== null) { ctx.updateEvent('context.traits.email', email) }
        if (first_name !== null) { ctx.updateEvent('context.traits.first_name', first_name) }
        if (last_name !== null) { ctx.updateEvent('context.traits.last_name', last_name) }
        if (city !== null) { ctx.updateEvent('context.traits.address.city', city) }
        if (country !== null) { ctx.updateEvent('context.traits.address.country', country) }
        if (postal_code !== null) { ctx.updateEvent('context.traits.address.postal_code', postal_code) }

        return ctx
      },
    }
    analytics.register(eventEnrichment as any)

    // Provide the analytics instances to the whole app
    return {
      provide: {
        segment: analytics,
      },
    }
  },
})
