import { defineStore } from 'pinia'

interface MainCtaTarget {
  type: 'modal' | 'page' | 'section'
  id: string
}

interface Settings {
  theme: 'light' | 'dark'
  hide: boolean
  hideHamburgerMenu: boolean
  hideSearch: boolean
  hideLogin: boolean
  hideExploreCourses: boolean
  exploreCoursesLabel: string
  hideMainCta: boolean
  mainCtaText: string
  mainCtaTarget: MainCtaTarget
}

interface NavbarStore {
  isAlreadyInitialized: boolean
  finishedSetup: boolean
  defaultSettings: Settings
  settings: Settings
}

export const useNavbarStore = defineStore('navbar', {
  state: (): NavbarStore => ({
    isAlreadyInitialized: false,
    finishedSetup: false,
    defaultSettings: {} as Settings,
    settings: {} as Settings,
  }),

  actions: {
    async setup() {
      const storyblokApiInstance = useStoryblokApi()
      const { data } = await storyblokApiInstance.get('cdn/stories/config', { version: 'published' })
      this.init(data.story.content.navbar[0], true)
      this.isAlreadyInitialized = true
      this.finishedSetup = true
    },

    useDefaultSettings() {
      this.settings = { ...this.defaultSettings }
    },

    init(blok: any, isGlobal: boolean) {
      const settings: Settings = {
        theme: blok.theme || 'dark',
        hide: blok.hide || false,
        hideHamburgerMenu: blok.hide_hamburger_menu || false,
        hideSearch: blok.hide_search || false,
        hideLogin: blok.hide_login || false,
        hideExploreCourses: blok.hide_explore_courses || false,
        exploreCoursesLabel: blok.explore_courses_label || '',
        hideMainCta: blok.hide_main_cta || false,
        mainCtaText: blok.main_cta_text || '',
        mainCtaTarget: {
          type: blok.main_cta_target?.[0]?.type || 'page',
          id: blok.main_cta_target?.[0]?.id || '',
        },
      }

      if (isGlobal) {
        this.defaultSettings = settings
        this.useDefaultSettings()
      }
      else {
        this.settings = settings
      }
    },
  },
})
