import type { $Fetch } from 'nitropack'
import type { paths } from '~/interfaces/api/api'

type PostProgressOperation = paths['/progress']['post']
type PostProgressOperationRequestBody = NonNullable<PostProgressOperation['requestBody']>['content']['application/json']
type PostProgressOperationResponseBody = PostProgressOperation['responses']['201']['content']['application/json']

export interface ProgressRepository {
  postProgress: (body: PostProgressOperationRequestBody) => Promise<PostProgressOperationResponseBody>
}

export function progressRepository(fetch: $Fetch) {
  return ({
    async postProgress(body: PostProgressOperationRequestBody): Promise<PostProgressOperationResponseBody> {
      return await fetch('/progress', {
        method: 'POST',
        body,
      })
    },
  }) as ProgressRepository
}
