import type { $Fetch } from 'nitropack'
import type { paths } from '~/interfaces/api/api'

type GetClientCategoriesSlugOperation = paths['/clients/categories/{slug}']['get']
type GetClientCategoriesSlugOperationOperationParamsQuery = GetClientCategoriesSlugOperation['parameters']['query']
type GetClientCategoriesSlugOperationOperationResponseBody = GetClientCategoriesSlugOperation['responses']['200']['content']['application/json']

export interface ClientRepository {
  getClientCategoriesSlug: (slug: string, query: GetClientCategoriesSlugOperationOperationParamsQuery) => Promise<GetClientCategoriesSlugOperationOperationResponseBody>
}

export function clientRepository(fetch: $Fetch) {
  return ({
    async getClientCategoriesSlug(slug: string, query: GetClientCategoriesSlugOperationOperationParamsQuery): Promise<GetClientCategoriesSlugOperationOperationResponseBody> {
      return await fetch(`/clients/categories/${slug}`, {
        query,
      })
    },
  }) as ClientRepository
}
