import { defineStore } from 'pinia'

interface CookieStore {
  cookiesEssential: boolean
  cookiesMarketing: boolean
  cookiesAnalytics: boolean
  cookiesPerformance: boolean
}

export const useCookieStore = defineStore('cookie', {
  state: () => {
    return {
      cookiesEssential: false,
      cookiesMarketing: false,
      cookiesAnalytics: false,
      cookiesPerformance: false,
    } as CookieStore
  },
  persist: {
    storage: persistedState.localStorage,
  },
})
