import { defineStore } from 'pinia'
import type { components } from '~/interfaces/api/api'

type Auth = components['schemas']['Auth']

interface AuthStore {
  isAuthenticated: boolean
  currentUserId: number | undefined | string
  currentUserFirstName: string | null | undefined
  currentUserNickname: string | undefined
  currentUserEmail: string | undefined
  authToken: string | undefined
  authTokenExpiresAt: number
  refreshToken: string | undefined
  isRefreshing: boolean
}

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      isAuthenticated: false,
      currentUserId: 0,
      currentUserFirstName: '',
      currentUserNickname: '',
      currentUserEmail: '',
      authToken: '',
      authTokenExpiresAt: 0,
      refreshToken: '',
      isRefreshing: false,
    } as AuthStore
  },
  persist: {
    storage: persistedState.cookiesWithOptions({
      maxAge: 60 * 60 * 24 * 30, // 30 days
    }),
  },
  actions: {
    async forceLogout() {
      // Set logout
      this.isAuthenticated = false
      this.currentUserId = 0
      this.currentUserFirstName = ''
      this.currentUserNickname = ''
      this.currentUserEmail = ''
      this.authToken = ''
      this.authTokenExpiresAt = 0
      this.refreshToken = ''
      this.isRefreshing = false

      // Navigate to the login page
      await navigateTo(useNuxtApp().$localePath('/login'))
    },
    async logout() {
      try {
        this.isRefreshing = false
        await useNuxtApp().$api.auth.postAuthLogout({ refresh_token: this.refreshToken || '' })
        await this.forceLogout()
      }
      catch (error) {
        console.error('Error during API logout:', error)
        await this.forceLogout()
      }
    },
    async refreshAuthToken() {
      try {
        this.isRefreshing = true
        const response = await useNuxtApp().$api.auth.postAuthRefresh({ refresh_token: this.refreshToken || '' })
        this.setAuthObject(response)
        this.isRefreshing = false
      }
      catch (error) {
        this.isRefreshing = false
        console.error('Error refreshing auth token:', error)
        await this.forceLogout()
      }
    },
    setAuthObject(authObject: Auth) {
      // Set the state
      this.currentUserFirstName = authObject.first_name
      this.currentUserNickname = authObject.nickname
      this.currentUserEmail = authObject.email
      this.isAuthenticated = true
      this.authToken = authObject.auth_token
      this.authTokenExpiresAt = authObject.expires_at
      this.refreshToken = authObject.refresh_token
      this.currentUserId = authObject.user_id
    },
  },
  getters: {
    isAuthTokenExpired(state: AuthStore) {
      const currentEpochTime = Math.floor(Date.now() / 1000)
      return currentEpochTime >= state.authTokenExpiresAt
    },
  },
})
