import type { $Fetch } from 'nitropack'
import type { paths } from '~/interfaces/api/api'

type GetBundlesOperation = paths['/bundles']['get']
type GetBundlesOperationOperationResponseBody = GetBundlesOperation['responses']['200']['content']['application/json']

type GetBundlesSlugOperation = paths['/bundles/{slug}']['get']
type GetBundlesSlugOperationOperationQueryParams = GetBundlesSlugOperation['parameters']['query']
type GetBundlesSlugOperationOperationResponseBody = GetBundlesSlugOperation['responses']['200']['content']['application/json']

export interface BundleRepository {
  getBundles: () => Promise<GetBundlesOperationOperationResponseBody>
  getBundlesSlug: (slug: string, query: GetBundlesSlugOperationOperationQueryParams) => Promise<GetBundlesSlugOperationOperationResponseBody>
}

export function bundleRepository(fetch: $Fetch) {
  return ({
    async getBundles(): Promise<GetBundlesOperationOperationResponseBody> {
      return await fetch('/bundles')
    },

    async getBundlesSlug(slug: string, query: GetBundlesSlugOperationOperationQueryParams): Promise<GetBundlesSlugOperationOperationResponseBody> {
      return await fetch(`/bundles/${slug}`, {
        query,
      })
    },
  }) as BundleRepository
}
