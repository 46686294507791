export default defineNuxtRouteMiddleware(async (to, _from) => {
  const authStore = useAuthStore()
  const nuxtApp = useNuxtApp()
  const { isAuthenticated, refreshToken } = storeToRefs(authStore)

  // If the user is authenticated
  if (isAuthenticated.value === true) {
    // Always refresh the auth token on server side requests
    if (import.meta.server) {
      try {
        const response = await nuxtApp.$api.auth.postAuthRefresh({ refresh_token: refreshToken.value || '' })
        authStore.setAuthObject(response)
      }
      catch (error) {
        authStore.logout()
        return
      }
    }

    // Refresh the auth token if it's expired
    if (authStore.isAuthTokenExpired) {
      await authStore.refreshAuthToken()
    }

    // fill the store with the user data
    const meStore = useMeStore()
    if (!meStore.me.id) {
      await meStore.fillStore()
    }

    // Redirect to app course detail page
    if (to.path && to.path.includes('/experience/') && to.name.includes('app') === false) {
      return navigateTo(useNuxtApp().$localePath({ name: 'app-experience-slug', params: to.params, query: to.query }))
    }

    if (to.path && to.path.includes('/experiences/') && to.name.includes('app') === false) {
      // Navigate to app experiences category page
      return navigateTo(useNuxtApp().$localePath({ name: 'app-experiences-category-subcategory', params: to.params, query: to.query }))
    }

    // Redirect from login to app
    if (to.path.includes('login')) {
      return navigateTo(useNuxtApp().$localePath({
        name: 'app',
        query: to.query,
      }))
    }
  }
  else if (!authStore.isAuthenticated && to.path.includes('/app')) {
    return navigateTo(useNuxtApp().$localePath('/login'))
  }
})
