import { default as _91_46_46_46slug_93HxQxjzxhjNMeta } from "/opt/buildhome/repo/pages/[...slug].vue?macro=true";
import { default as contactUHSJWY2vgnMeta } from "/opt/buildhome/repo/pages/app/contact.vue?macro=true";
import { default as _91slug_93TMKfXjUZViMeta } from "/opt/buildhome/repo/pages/app/experience/[slug].vue?macro=true";
import { default as _91_91subcategory_93_93LAZck7JropMeta } from "/opt/buildhome/repo/pages/app/experiences/[category]/[[subcategory]].vue?macro=true";
import { default as indexIbNDbq129sMeta } from "/opt/buildhome/repo/pages/app/index.vue?macro=true";
import { default as libraryBUfycn5PHqMeta } from "/opt/buildhome/repo/pages/app/library.vue?macro=true";
import { default as indexLGFSnAPRvSMeta } from "/opt/buildhome/repo/pages/app/live-calls/index.vue?macro=true";
import { default as settingsrKDCJ7BrX5Meta } from "/opt/buildhome/repo/pages/app/settings.vue?macro=true";
import { default as start_45sessionTVdztpc7rWMeta } from "/opt/buildhome/repo/pages/app/start-session.vue?macro=true";
import { default as indexMbdvphl0nlMeta } from "/opt/buildhome/repo/pages/bundle/[slug]/index.vue?macro=true";
import { default as index06KHav2c0RMeta } from "/opt/buildhome/repo/pages/checkout/[slug]/index.vue?macro=true";
import { default as _91version_93KqizJ8BY4lMeta } from "/opt/buildhome/repo/pages/experience/[slug]/[version].vue?macro=true";
import { default as indexpyGNRzjiZUMeta } from "/opt/buildhome/repo/pages/experience/[slug]/index.vue?macro=true";
import { default as _91_91subcategory_93_93cqX1NgCmsRMeta } from "/opt/buildhome/repo/pages/experiences/[category]/[[subcategory]].vue?macro=true";
import { default as _91token_93ivbA4otmZRMeta } from "/opt/buildhome/repo/pages/login/forgotten-password/[token].vue?macro=true";
import { default as indexwCIiGVNI9dMeta } from "/opt/buildhome/repo/pages/onboarding/android/index.vue?macro=true";
import { default as indexy4E1a1O27hMeta } from "/opt/buildhome/repo/pages/onboarding/ios/index.vue?macro=true";
import { default as indexOnsfMlpWLxMeta } from "/opt/buildhome/repo/pages/session/[slug]/index.vue?macro=true";
import { default as livecWNf0ZQtjOMeta } from "/opt/buildhome/repo/pages/session/[slug]/live.vue?macro=true";
import { default as successrZnyTFvha2Meta } from "/opt/buildhome/repo/pages/session/[slug]/success.vue?macro=true";
import { default as component_45stubGZr1UkTkg3Meta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGZr1UkTkg3 } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/[...slug].vue")
  },
  {
    name: "app-contact___en",
    path: "/en/app/contact",
    meta: contactUHSJWY2vgnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/contact.vue")
  },
  {
    name: "app-experience-slug___en",
    path: "/en/app/experience/:slug()",
    meta: _91slug_93TMKfXjUZViMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/experience/[slug].vue")
  },
  {
    name: "app-experiences-category-subcategory___en",
    path: "/en/app/experiences/:category()/:subcategory?",
    meta: _91_91subcategory_93_93LAZck7JropMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/experiences/[category]/[[subcategory]].vue")
  },
  {
    name: "app___en",
    path: "/en/app",
    meta: indexIbNDbq129sMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/index.vue")
  },
  {
    name: "app-library___en",
    path: "/en/app/library",
    meta: libraryBUfycn5PHqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/library.vue")
  },
  {
    name: "app-live-calls___en",
    path: "/en/app/live-calls",
    meta: indexLGFSnAPRvSMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/live-calls/index.vue")
  },
  {
    name: "app-settings___en",
    path: "/en/app/settings",
    meta: settingsrKDCJ7BrX5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/app/settings.vue")
  },
  {
    name: "app-start-session___en",
    path: "/en/app/start-session",
    meta: start_45sessionTVdztpc7rWMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/start-session.vue")
  },
  {
    name: "bundle-slug___en",
    path: "/en/bundle/:slug()",
    component: () => import("/opt/buildhome/repo/pages/bundle/[slug]/index.vue")
  },
  {
    name: "checkout-slug___en",
    path: "/en/checkout/:slug()",
    meta: index06KHav2c0RMeta || {},
    component: () => import("/opt/buildhome/repo/pages/checkout/[slug]/index.vue")
  },
  {
    name: "experience-slug-version___en",
    path: "/en/experience/:slug()/:version()",
    component: () => import("/opt/buildhome/repo/pages/experience/[slug]/[version].vue")
  },
  {
    name: "experience-slug___en",
    path: "/en/experience/:slug()",
    component: () => import("/opt/buildhome/repo/pages/experience/[slug]/index.vue")
  },
  {
    name: "experiences-category-subcategory___en",
    path: "/en/experiences/:category()/:subcategory?",
    component: () => import("/opt/buildhome/repo/pages/experiences/[category]/[[subcategory]].vue")
  },
  {
    name: "login-forgotten-password-token___en",
    path: "/en/login/forgotten-password/:token()",
    component: () => import("/opt/buildhome/repo/pages/login/forgotten-password/[token].vue")
  },
  {
    name: "onboarding-android___en",
    path: "/en/onboarding/android",
    meta: indexwCIiGVNI9dMeta || {},
    component: () => import("/opt/buildhome/repo/pages/onboarding/android/index.vue")
  },
  {
    name: "onboarding-ios___en",
    path: "/en/onboarding/ios",
    meta: indexy4E1a1O27hMeta || {},
    component: () => import("/opt/buildhome/repo/pages/onboarding/ios/index.vue")
  },
  {
    name: "session-slug___en",
    path: "/en/session/:slug()",
    component: () => import("/opt/buildhome/repo/pages/session/[slug]/index.vue")
  },
  {
    name: "session-slug-live___en",
    path: "/en/session/:slug()/live",
    meta: livecWNf0ZQtjOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/session/[slug]/live.vue")
  },
  {
    name: "session-slug-success___en",
    path: "/en/session/:slug()/success",
    meta: successrZnyTFvha2Meta || {},
    component: () => import("/opt/buildhome/repo/pages/session/[slug]/success.vue")
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubGZr1UkTkg3
  }
]