import { defineStore } from 'pinia'
import type { components } from '~/interfaces/api/api'

type Me = components['schemas']['UserDetail']

interface MeStore {
  me: Me
}

export const useMeStore = defineStore('me', {
  state: () => {
    return {
      me: {},
    } as MeStore
  },
  actions: {
    async fillStore() {
      // Fetch User and courses of User
      const meData: Me = await (useNuxtApp() as any).$api.me.getMe()
      this.me = meData
    },
  },
})
