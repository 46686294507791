import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/opt/buildhome/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/opt/buildhome/repo/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_hBLWEPkA3b from "/opt/buildhome/repo/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import components_client_rgwwAZDE5D from "/opt/buildhome/repo/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.mjs";
import plugin_iGLjBbor9q from "/opt/buildhome/repo/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_ghbUAjaD3n from "/opt/buildhome/repo/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_AUP22rrBAc from "/opt/buildhome/repo/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import analytics_client_mtqVw3YRXk from "/opt/buildhome/repo/plugins/analytics.client.ts";
import api_GFfDXud5Cr from "/opt/buildhome/repo/plugins/api.ts";
import preview_6oGpsaen2C from "/opt/buildhome/repo/plugins/preview.ts";
import scrollPolyfill_client_GnzOerVE0q from "/opt/buildhome/repo/plugins/scrollPolyfill.client.js";
import socialShare_mOLWZwVWh9 from "/opt/buildhome/repo/plugins/socialShare.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_hBLWEPkA3b,
  components_client_rgwwAZDE5D,
  plugin_iGLjBbor9q,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_ghbUAjaD3n,
  plugin_AUP22rrBAc,
  analytics_client_mtqVw3YRXk,
  api_GFfDXud5Cr,
  preview_6oGpsaen2C,
  scrollPolyfill_client_GnzOerVE0q,
  socialShare_mOLWZwVWh9
]