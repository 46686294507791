import type { $Fetch } from 'nitropack'
import type { paths } from '~/interfaces/api/api'

type PostAccessOperation = paths['/access']['post']
type PostAccessOperationsOperationQueryParams = PostAccessOperation['parameters']['query']
type PostAccessOperationOperationResponseBody = PostAccessOperation['responses']['200']['content']['application/json']

export interface AccessRepository {
  postAccess: (query: PostAccessOperationsOperationQueryParams) => Promise<PostAccessOperationOperationResponseBody>
}

export function accessRepository(fetch: $Fetch) {
  return ({
    async postAccess(query: PostAccessOperationsOperationQueryParams): Promise<PostAccessOperationOperationResponseBody> {
      return await fetch('/access', {
        method: 'POST',
        query,
      })
    },
  }) as AccessRepository
}
