import type { $Fetch } from 'nitropack'
import type { paths } from '~/interfaces/api/api'

type PostAuthRegisterOperation = paths['/auth/register']['post']
type PostAuthRegisterOperationRequestBody = NonNullable<PostAuthRegisterOperation['requestBody']>['content']['application/json']
type PostAuthRegisterOperationResponseBody = PostAuthRegisterOperation['responses']['200']['content']['application/json']

type PostAuthLoginOperation = paths['/auth/login']['post']
type PostAuthLoginOperationRequestBody = NonNullable<PostAuthLoginOperation['requestBody']>['content']['application/json']
type PostAuthLoginOperationResponseBody = PostAuthLoginOperation['responses']['200']['content']['application/json']

type PostAuthLoginAppleOperation = paths['/auth/login_apple']['post']
type PostAuthLoginAppleOperationRequestBody = NonNullable<PostAuthLoginAppleOperation['requestBody']>['content']['application/json']
type PostAuthLoginAppleOperationResponseBody = PostAuthLoginAppleOperation['responses']['200']['content']['application/json']

type PostAuthLoginGoogleOperation = paths['/auth/login_google']['post']
type PostAuthLoginGoogleOperationRequestBody = NonNullable<PostAuthLoginGoogleOperation['requestBody']>['content']['application/json']
type PostAuthLoginGoogleOperationResponseBody = PostAuthLoginGoogleOperation['responses']['200']['content']['application/json']

type PostAuthRefreshOperation = paths['/auth/refresh']['post']
type PostAuthRefreshOperationRequestBody = NonNullable<PostAuthRefreshOperation['requestBody']>['content']['application/json']
type PostAuthRefreshOperationResponseBody = PostAuthRefreshOperation['responses']['200']['content']['application/json']

type PostAuthLogoutOperation = paths['/auth/logout']['post']
type PostAuthLogoutOperationRequestBody = NonNullable<PostAuthLogoutOperation['requestBody']>['content']['application/json']
type PostAuthLogoutOperationResponseBody = PostAuthLogoutOperation['responses']['204']['content']['application/json']

type PostAuthSendConfirmationInstructionsOperation = paths['/auth/send_confirmation_instructions']['post']
type PostAuthSendConfirmationInstructionsOperationRequestBody = NonNullable<PostAuthSendConfirmationInstructionsOperation['requestBody']>['content']['application/json']
type PostAuthSendConfirmationInstructionsOperationResponseBody = PostAuthSendConfirmationInstructionsOperation['responses']['200']['content']['application/json']

type PostAuthConfirmAccountOperation = paths['/auth/confirm_account']['post']
type PostAuthConfirmAccountOperationRequestBody = NonNullable<PostAuthConfirmAccountOperation['requestBody']>['content']['application/json']
type PostAuthConfirmAccountOperationResponseBody = PostAuthConfirmAccountOperation['responses']['200']['content']['application/json']

type PostAuthSendResetPasswordInstructionsOperation = paths['/auth/send_reset_password_instructions']['post']
type PostAuthSendResetPasswordInstructionsOperationRequestBody = NonNullable<PostAuthSendResetPasswordInstructionsOperation['requestBody']>['content']['application/json']
type PostAuthSendResetPasswordInstructionsOperationResponseBody = PostAuthSendResetPasswordInstructionsOperation['responses']['200']['content']['application/json']

type PostAuthResetPasswordOperation = paths['/auth/reset_password']['post']
type PostAuthResetPasswordOperationRequestBody = NonNullable<PostAuthResetPasswordOperation['requestBody']>['content']['application/json']
type PostAuthResetPasswordOperationResponseBody = PostAuthResetPasswordOperation['responses']['200']['content']['application/json']

type PostAuthChangePasswordOperation = paths['/auth/change_password']['post']
type PostAuthChangePasswordOperationRequestBody = NonNullable<PostAuthChangePasswordOperation['requestBody']>['content']['application/json']
type PostAuthChangePasswordOperationResponseBody = PostAuthChangePasswordOperation['responses']['200']['content']['application/json']

export interface AuthRepository {
  postAuthRegister: (params: PostAuthRegisterOperationRequestBody) => Promise<PostAuthRegisterOperationResponseBody>
  postAuthLogin: (params: PostAuthLoginOperationRequestBody) => Promise<PostAuthLoginOperationResponseBody>
  postAuthLoginApple: (body: PostAuthLoginAppleOperationRequestBody) => Promise<PostAuthLoginAppleOperationResponseBody>
  postAuthLoginGoogle: (params: PostAuthLoginGoogleOperationRequestBody) => Promise<PostAuthLoginGoogleOperationResponseBody>
  postAuthRefresh: (params: PostAuthRefreshOperationRequestBody) => Promise<PostAuthRefreshOperationResponseBody>
  postAuthLogout: (body: PostAuthLogoutOperationRequestBody) => Promise<PostAuthLogoutOperationResponseBody>
  postAuthSendConfirmationInstructions: (params: PostAuthSendConfirmationInstructionsOperationRequestBody) => Promise<PostAuthSendConfirmationInstructionsOperationResponseBody>
  postAuthConfirmAccount: (params: PostAuthConfirmAccountOperationRequestBody) => Promise<PostAuthConfirmAccountOperationResponseBody>
  postAuthSendResetPasswordInstructions: (params: PostAuthSendResetPasswordInstructionsOperationRequestBody) => Promise<PostAuthSendResetPasswordInstructionsOperationResponseBody>
  postAuthResetPassword: (params: PostAuthResetPasswordOperationRequestBody) => Promise<PostAuthResetPasswordOperationResponseBody>
  postAuthChangePassword: (params: PostAuthChangePasswordOperationRequestBody) => Promise<PostAuthChangePasswordOperationResponseBody>
}

export function authRepository(fetch: $Fetch) {
  return ({
    async postAuthRegister(params: PostAuthRegisterOperationRequestBody): Promise<PostAuthRegisterOperationResponseBody> {
      return await fetch('/auth/register', {
        method: 'POST',
        body: params,
      })
    },

    async postAuthLogin(params: PostAuthLoginOperationRequestBody): Promise<PostAuthLoginOperationResponseBody> {
      return await fetch('/auth/login', {
        method: 'POST',
        body: params,
      })
    },

    async postAuthLoginApple(body: PostAuthLoginAppleOperationRequestBody): Promise<PostAuthLoginAppleOperationResponseBody> {
      return await fetch('/auth/login_apple', {
        method: 'POST',
        body,
      })
    },

    async postAuthLoginGoogle(body: PostAuthLoginGoogleOperationRequestBody): Promise<PostAuthLoginGoogleOperationResponseBody> {
      return await fetch('/auth/login_google', {
        method: 'POST',
        body,
      })
    },

    async postAuthRefresh(params: PostAuthRefreshOperationRequestBody): Promise<PostAuthRefreshOperationResponseBody> {
      return await fetch('/auth/refresh', {
        method: 'POST',
        body: params,
      })
    },

    async postAuthLogout(body: PostAuthLogoutOperationRequestBody): Promise<PostAuthLogoutOperationResponseBody> {
      return await fetch('/auth/logout', {
        method: 'POST',
        body,
      })
    },

    async postAuthSendConfirmationInstructions(params: PostAuthSendConfirmationInstructionsOperationRequestBody): Promise<PostAuthSendConfirmationInstructionsOperationResponseBody> {
      return await fetch('/auth/send_confirmation_instructions', {
        method: 'POST',
        body: params,
      })
    },

    async postAuthConfirmAccount(params: PostAuthConfirmAccountOperationRequestBody): Promise<PostAuthConfirmAccountOperationResponseBody> {
      return await fetch('/auth/confirm_account', {
        method: 'POST',
        body: params,
      })
    },

    async postAuthSendResetPasswordInstructions(params: PostAuthSendResetPasswordInstructionsOperationRequestBody): Promise<PostAuthSendResetPasswordInstructionsOperationResponseBody> {
      return await fetch('/auth/send_reset_password_instructions', {
        method: 'POST',
        body: params,
      })
    },

    async postAuthResetPassword(params: PostAuthResetPasswordOperationRequestBody): Promise<PostAuthResetPasswordOperationResponseBody> {
      return await fetch('/auth/reset_password', {
        method: 'POST',
        body: params,
      })
    },

    async postAuthChangePassword(params: PostAuthChangePasswordOperationRequestBody): Promise<PostAuthChangePasswordOperationResponseBody> {
      return await fetch('/auth/change_password', {
        method: 'POST',
        body: params,
      })
    },
  }) as AuthRepository
}
