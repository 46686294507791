import type { $Fetch } from 'nitropack'
import type { paths } from '~/interfaces/api/api'

type GetLiveCallsOperation = paths['/live_calls']['get']
type GetLiveCallsOperationOperationParams = GetLiveCallsOperation['parameters']['query']
type GetLiveCallsOperationOperationResponseBody = GetLiveCallsOperation['responses']['200']['content']['application/json']

type GetLiveCallsNextOperation = paths['/live_calls/next']['get']
type GetLiveCallsNextOperationOperationResponseBody = GetLiveCallsNextOperation['responses']['200']['content']['application/json']

type GetLiveCallsSlugOperation = paths['/live_calls/{slug}']['get']
type GetLiveCallsSlugOperationOperationResponseBody = GetLiveCallsSlugOperation['responses']['200']['content']['application/json']

type PostLiveCallsSlugSessionsOperation = paths['/live_calls/{slug}/sessions']['post']
type PostLiveCallsSlugSessionsOperationRequestBody = NonNullable<PostLiveCallsSlugSessionsOperation['requestBody']>['content']['application/json']
type PostLiveCallsSlugSessionsOperationResponseBody = PostLiveCallsSlugSessionsOperation['responses']['201']['content']['application/json']

type GetLiveCallsSlugSessionsIdOperation = paths['/live_calls/{slug}/sessions/{id}']['get']
type GetLiveCallsSlugSessionsIdOperationResponseBody = GetLiveCallsSlugSessionsIdOperation['responses']['200']['content']['application/json']

type PutLiveCallsSlugSessionsIdOperationConfirm = paths['/live_calls/{slug}/sessions/{id}/confirm']['put']
type PutLiveCallsSlugSessionsIdOperationConfirmResponseBody = PutLiveCallsSlugSessionsIdOperationConfirm['responses']['200']['content']['application/json']

export interface LiveCallRepository {
  getLiveCalls: (query: GetLiveCallsOperationOperationParams) => Promise<GetLiveCallsOperationOperationResponseBody>
  getLiveCallsNext: () => Promise<GetLiveCallsNextOperationOperationResponseBody>
  getLiveCallsSlug: (slug: string) => Promise<GetLiveCallsSlugOperationOperationResponseBody>
  postLiveCallsSlugSessions: (slug: string, body: PostLiveCallsSlugSessionsOperationRequestBody) => Promise<PostLiveCallsSlugSessionsOperationResponseBody>
  getLiveCallsSlugSessionsId: (slug: string, id: string) => Promise<GetLiveCallsSlugSessionsIdOperationResponseBody>
  putLiveCallsSlugSessionsIdConfirm: (slug: string, id: string) => Promise<PutLiveCallsSlugSessionsIdOperationConfirmResponseBody>
}

export function liveCallRepository(fetch: $Fetch) {
  return ({
    async getLiveCalls(query: GetLiveCallsOperationOperationParams): Promise<GetLiveCallsOperationOperationResponseBody> {
      return await fetch('/live_calls', {
        query,
      })
    },

    async getLiveCallsNext(): Promise<GetLiveCallsNextOperationOperationResponseBody> {
      return await fetch('/live_calls/next')
    },

    async getLiveCallsSlug(slug: string): Promise<GetLiveCallsSlugOperationOperationResponseBody> {
      return await fetch(`/live_calls/${slug}`)
    },

    async postLiveCallsSlugSessions(slug: string, body: PostLiveCallsSlugSessionsOperationRequestBody): Promise<PostLiveCallsSlugSessionsOperationResponseBody> {
      return await fetch(`/live_calls/${slug}/sessions`, {
        method: 'POST',
        body,
      })
    },

    async getLiveCallsSlugSessionsId(slug: string, id: string): Promise<GetLiveCallsSlugSessionsIdOperationResponseBody> {
      return await fetch(`/live_calls/${slug}/sessions/${id}`)
    },

    async putLiveCallsSlugSessionsIdConfirm(slug: string, id: string): Promise<PutLiveCallsSlugSessionsIdOperationConfirmResponseBody> {
      return await fetch(`/live_calls/${slug}/sessions/${id}/confirm`, {
        method: 'PUT',
      })
    },
  }) as LiveCallRepository
}
