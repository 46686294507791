import type { $Fetch } from 'nitropack'
import type { paths } from '~/interfaces/api/api'

type PostPaymentsCheckoutOperation = paths['/payments/checkout']['post']
type PostPaymentsCheckoutOperationRequestBody = NonNullable<PostPaymentsCheckoutOperation['requestBody']>['content']['application/json']
type PostPaymentsCheckoutOperationResponseBody = PostPaymentsCheckoutOperation['responses']['201']['content']['application/json']

type GetPaymentsCheckoutSuccessOperation = paths['/payments/checkout/success']['get']
type GetPaymentsCheckoutSuccessOperationOperationQueryParams = GetPaymentsCheckoutSuccessOperation['parameters']['query']
type GetPaymentsCheckoutSuccessOperationOperationResponseBody = GetPaymentsCheckoutSuccessOperation['responses']['200']['content']['application/json']

export interface PaymentRepository {
  postPaymentsCheckout: (body: PostPaymentsCheckoutOperationRequestBody) => Promise<PostPaymentsCheckoutOperationResponseBody>
  getPaymentsCheckoutSuccess: (query: GetPaymentsCheckoutSuccessOperationOperationQueryParams) => Promise<GetPaymentsCheckoutSuccessOperationOperationResponseBody>
}

export function paymentRepository(fetch: $Fetch) {
  return ({
    async postPaymentsCheckout(body: PostPaymentsCheckoutOperationRequestBody): Promise<PostPaymentsCheckoutOperationResponseBody> {
      return await fetch('/payments/checkout', {
        method: 'POST',
        body,
      })
    },

    // eslint-disable-next-line unused-imports/no-unused-vars
    async getPaymentsCheckoutSuccess(query: GetPaymentsCheckoutSuccessOperationOperationQueryParams): Promise<GetPaymentsCheckoutSuccessOperationOperationResponseBody> {
      return await fetch('/payments/checkout/success')
    },
  }) as PaymentRepository
}
