import type { $Fetch } from 'nitropack'
import type { paths } from '~/interfaces/api/api'

type PostSearchOperation = paths['/search']['post']
type PostSearchOperationParams = PostSearchOperation['parameters']['query']
type PostSearchOperationResponseBody = PostSearchOperation['responses']['200']['content']['application/json']

export interface SearchRepository {
  postSearch: (query: PostSearchOperationParams) => Promise<PostSearchOperationResponseBody>
}

export function searchRepository(fetch: $Fetch) {
  return ({
    async postSearch(query: PostSearchOperationParams): Promise<PostSearchOperationResponseBody> {
      return await fetch('/search', {
        method: 'POST',
        query,
      })
    },
  }) as SearchRepository
}
