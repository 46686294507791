import type { $Fetch } from 'nitropack'
import type { paths } from '~/interfaces/api/api'

type GetCoursesOperation = paths['/courses']['get']
type GetCoursesOperationRequestBody = GetCoursesOperation['parameters']['query']
type GetCoursesOperationOperationResponseBody = GetCoursesOperation['responses']['200']['content']['application/json']

type GetCoursesSlugOperation = paths['/courses/{slug}']['get']
type GetCoursesSlugOperationOperationResponseBody = GetCoursesSlugOperation['responses']['200']['content']['application/json']

type GetCoursesCourseOfTheWeekOperation = paths['/courses/course_of_the_week']['get']
type GetCoursesCourseOfTheWeekOperationOperationResponseBody = GetCoursesCourseOfTheWeekOperation['responses']['200']['content']['application/json']

type PostCoursesSlugFollowsOperation = paths['/courses/{slug}/follows']['post']
type PostCoursesSlugFollowsOperationOperationResponseBody = PostCoursesSlugFollowsOperation['responses']['204']['content']['application/json']

type DeleteCoursesSlugFollowsOperation = paths['/courses/{slug}/follows']['delete']
type DeleteCoursesSlugFollowsOperationOperationResponseBody = DeleteCoursesSlugFollowsOperation['responses']['204']['content']['application/json']

type GetCoursesSlugRatingsOperation = paths['/courses/{slug}/ratings']['get']
type GetCoursesSlugRatingsOperationOperationResponseBody = GetCoursesSlugRatingsOperation['responses']['200']['content']['application/json']

type GetCoursesSlugLessonsOperation = paths['/courses/{slug}/lessons']['get']
type GetCoursesSlugLessonsOperationOperationResponseBody = GetCoursesSlugLessonsOperation['responses']['200']['content']['application/json']

type GetCoursesSlugLessonsLessonsSlugOperation = paths['/courses/{slug}/lessons/{lessonSlug}']['get']
type GetCoursesSlugLessonsLessonsSlugOperationOperationResponseBody = GetCoursesSlugLessonsLessonsSlugOperation['responses']['200']['content']['application/json']

type GetCoursesCategoriesOperation = paths['/courses/categories']['get']
type GetCoursesCategoriesOperationOperationResponseBody = GetCoursesCategoriesOperation['responses']['200']['content']['application/json']

type GetCoursesCategoriesSlugOperation = paths['/courses/categories/{slug}']['get']
type GetCoursesCategoriesSlugOperationOperationRequestBody = GetCoursesCategoriesSlugOperation['parameters']['query']
type GetCoursesCategoriesSlugOperationOperationResponseBody = GetCoursesCategoriesSlugOperation['responses']['200']['content']['application/json']

type PostCoursesCategoriesSlugFollowsOperation = paths['/courses/categories/{slug}/follows']['post']
type PostCoursesCategoriesSlugFollowsOperationOperationResponseBody = PostCoursesCategoriesSlugFollowsOperation['responses']['204']['content']['application/json']

type DeleteCoursesCategoriesSlugFollowsOperation = paths['/courses/categories/{slug}/follows']['delete']
type DeleteCoursesCategoriesSlugFollowsOperationOperationResponseBody = DeleteCoursesCategoriesSlugFollowsOperation['responses']['204']['content']['application/json']

export interface CourseRepository {
  getCourses: () => Promise<GetCoursesOperationOperationResponseBody>
  getCoursesSlug: (slug: string) => Promise<GetCoursesSlugOperationOperationResponseBody>
  getCoursesCourseOfTheWeek: () => Promise<GetCoursesCourseOfTheWeekOperationOperationResponseBody>
  postCoursesSlugFollows: (slug: string) => Promise<PostCoursesSlugFollowsOperationOperationResponseBody>
  deleteCoursesSlugFollows: (slug: string) => Promise<DeleteCoursesSlugFollowsOperationOperationResponseBody>
  getCoursesSlugRatings: (slug: string) => Promise<GetCoursesSlugRatingsOperationOperationResponseBody>
  getCoursesSlugLessons: (slug: string) => Promise<GetCoursesSlugLessonsOperationOperationResponseBody>
  getCoursesSlugLessonsLessonsSlug: (slug: string, lessonsSlug: string) => Promise<GetCoursesSlugLessonsLessonsSlugOperationOperationResponseBody>
  getCoursesCategories: () => Promise<GetCoursesCategoriesOperationOperationResponseBody>
  getCoursesCategoriesSlug: (slug: string, query: GetCoursesCategoriesSlugOperationOperationRequestBody) => Promise<GetCoursesCategoriesSlugOperationOperationResponseBody>
  postCoursesCategoriesSlugFollows: (slug: string) => Promise<PostCoursesCategoriesSlugFollowsOperationOperationResponseBody>
  deleteCoursesCategoriesSlugFollows: (slug: string) => Promise<DeleteCoursesCategoriesSlugFollowsOperationOperationResponseBody>
}

export function courseRepository(fetch: $Fetch) {
  return ({
    async getCourses(query: GetCoursesOperationRequestBody): Promise<GetCoursesOperationOperationResponseBody> {
      return await fetch('/courses', {
        query,
      })
    },

    async getCoursesSlug(slug: string): Promise<GetCoursesSlugOperationOperationResponseBody> {
      return await fetch(`/courses/${slug}`)
    },

    async getCoursesCourseOfTheWeek(): Promise<GetCoursesCourseOfTheWeekOperationOperationResponseBody> {
      return await fetch('/courses/course_of_the_week')
    },

    async postCoursesSlugFollows(slug: string): Promise<PostCoursesSlugFollowsOperationOperationResponseBody> {
      return await fetch(`/courses/${slug}/follows`, {
        method: 'POST',
      })
    },

    async deleteCoursesSlugFollows(slug: string): Promise<DeleteCoursesSlugFollowsOperationOperationResponseBody> {
      return await fetch(`/courses/${slug}/follows`, {
        method: 'DELETE',
      })
    },

    async getCoursesSlugRatings(slug: string): Promise<GetCoursesSlugRatingsOperationOperationResponseBody> {
      return await fetch(`/courses/${slug}/ratings`)
    },

    async getCoursesSlugLessons(slug: string): Promise<GetCoursesSlugLessonsOperationOperationResponseBody> {
      return await fetch(`/courses/${slug}/lessons`)
    },

    async getCoursesSlugLessonsLessonsSlug(slug: string, lessonSlug: string): Promise<GetCoursesSlugLessonsLessonsSlugOperationOperationResponseBody> {
      return await fetch(`/courses/${slug}/lessons/${lessonSlug}`)
    },

    async getCoursesCategories(): Promise<GetCoursesCategoriesOperationOperationResponseBody> {
      return await fetch('/courses/categories')
    },

    async getCoursesCategoriesSlug(slug: string, query: GetCoursesCategoriesSlugOperationOperationRequestBody): Promise<GetCoursesCategoriesSlugOperationOperationResponseBody> {
      return await fetch(`/courses/categories/${slug}`, {
        query,
      })
    },

    async postCoursesCategoriesSlugFollows(slug: string): Promise<PostCoursesCategoriesSlugFollowsOperationOperationResponseBody> {
      return await fetch(`/courses/categories/${slug}/follows`, {
        method: 'POST',
      })
    },

    async deleteCoursesCategoriesSlugFollows(slug: string): Promise<DeleteCoursesCategoriesSlugFollowsOperationOperationResponseBody> {
      return await fetch(`/courses/categories/${slug}/follows`, {
        method: 'DELETE',
      })
    },
  }) as CourseRepository
}
