import type { $Fetch } from 'nitropack'
import type { paths } from '~/interfaces/api/api'

type GetMeOperation = paths['/me']['get']
type GetMeOperationOperationResponseBody = GetMeOperation['responses']['200']['content']['application/json']

type DeleteMeOperation = paths['/me']['delete']
type DeleteMeOperationOperationResponseBody = DeleteMeOperation['responses']['204']['content']['application/json']

type PatchMeUpdateNicknameOperation = paths['/me/update_nickname']['patch']
type PatchMeUpdateNicknameOperationRequestBody = NonNullable<PatchMeUpdateNicknameOperation['requestBody']>['content']['application/json']
type PatchMeUpdateNicknameOperationResponseBody = PatchMeUpdateNicknameOperation['responses']['200']['content']['application/json']

type PatchMeUpdateSettingsNotificationsOperation = paths['/me/update_settings_notifications']['patch']
type PatchMeUpdateSettingsNotificationsOperationRequestBody = NonNullable<PatchMeUpdateSettingsNotificationsOperation['requestBody']>['content']['application/json']
type PatchMeUpdateSettingsNotificationsOperationResponseBody = PatchMeUpdateSettingsNotificationsOperation['responses']['200']['content']['application/json']

type PatchMeUpdateHasSeenOnboardingSession = paths['/me/update_has_seen_onboarding_session']['patch']
type PatchMeUpdateHasSeenOnboardingSessionOperationResponseBody = PatchMeUpdateHasSeenOnboardingSession['responses']['200']['content']['application/json']

type PatchMeConfirmLegalNotice = paths['/me/confirm_legal_notice']['patch']
type PatchMeConfirmLegalNoticeOperationResponseBody = PatchMeConfirmLegalNotice['responses']['200']['content']['application/json']

type GetMeCoursesOperation = paths['/me/courses']['get']
type GetMeCoursesOperationParams = GetMeCoursesOperation['parameters']['query']
type GetMeCoursesOperationOperationResponseBody = GetMeCoursesOperation['responses']['200']['content']['application/json']

type GetMeBundlesOperation = paths['/me/bundles']['get']
type GetMeBundlesOperationOperationResponseBody = GetMeBundlesOperation['responses']['200']['content']['application/json']

type GetMeBumpersOperation = paths['/me/bumpers']['get']
type GetMeBumpersOperationOperationResponseBody = GetMeBumpersOperation['responses']['200']['content']['application/json']

type GetMeSubscriptionsOperation = paths['/me/subscriptions']['get']
type GetMeSubscriptionsOperationOperationResponseBody = GetMeSubscriptionsOperation['responses']['200']['content']['application/json']

type GetMeSubscriptionIdOperation = paths['/me/subscriptions/{id}']['get']
type GetMeSubscriptionIdOperationOperationResponseBody = GetMeSubscriptionIdOperation['responses']['200']['content']['application/json']

type PostMeSubscriptionsIdAddCouponCodeOperation = paths['/me/subscriptions/{id}/add_coupon_code']['post']
type PostMeSubscriptionsIdAddCouponCodeOperationRequestBody = NonNullable<PostMeSubscriptionsIdAddCouponCodeOperation['requestBody']>['content']['application/json']

type PostMeSubscriptionIdCreateCustomerPortalSessionOperation = paths['/me/subscriptions/{id}/create_customer_portal_session']['post']
type PostMeSubscriptionIdCreateCustomerPortalSessionOperationOperationResponseBody = PostMeSubscriptionIdCreateCustomerPortalSessionOperation['responses']['200']['content']['application/json']

type PutMeSubscriptionsIdCancelOperation = paths['/me/subscriptions/{id}/cancel']['put']
type PutMeSubscriptionsIdCancelOperationOperationResponseBody = PutMeSubscriptionsIdCancelOperation['responses']['200']['content']['application/json']

export interface MeRepository {
  getMe: () => Promise<GetMeOperationOperationResponseBody>
  deleteMe: () => Promise<DeleteMeOperationOperationResponseBody>
  patchMeUpdateNickname: (params: PatchMeUpdateNicknameOperationRequestBody) => Promise<PatchMeUpdateNicknameOperationResponseBody>
  patchMeUpdateSettingsNotifications: (params: PatchMeUpdateSettingsNotificationsOperationRequestBody) => Promise<PatchMeUpdateSettingsNotificationsOperationResponseBody>
  patchMeUpdateHasSeenOnboardingSession: () => Promise<PatchMeUpdateHasSeenOnboardingSessionOperationResponseBody>
  patchMeConfirmLegalNotice: () => Promise<PatchMeConfirmLegalNoticeOperationResponseBody>
  getMeCourses: (params: GetMeCoursesOperationParams) => Promise<GetMeCoursesOperationOperationResponseBody>
  getMeBundles: () => Promise<GetMeBundlesOperationOperationResponseBody>
  getMeBumpers: () => Promise<GetMeBumpersOperationOperationResponseBody>
  getMeSubscriptions: () => Promise<GetMeSubscriptionsOperationOperationResponseBody>
  getMeSubscriptionId: (id: number) => Promise<GetMeSubscriptionIdOperationOperationResponseBody>
  postMeSubscriptionsIdAddCouponCode: (id: number, body: PostMeSubscriptionsIdAddCouponCodeOperationRequestBody) => Promise<void>
  postMeSubscriptionIdCreateCustomerPortalSession: (id: number) => Promise<PostMeSubscriptionIdCreateCustomerPortalSessionOperationOperationResponseBody>
  putMeSubscriptionsIdCancel: (id: number) => Promise<PutMeSubscriptionsIdCancelOperationOperationResponseBody>
}

export function meRepository(fetch: $Fetch) {
  return ({
    async getMe(): Promise<GetMeOperationOperationResponseBody> {
      return await fetch('/me')
    },

    async deleteMe(): Promise<DeleteMeOperationOperationResponseBody> {
      return await fetch('/me', {
        method: 'DELETE',
      })
    },

    async patchMeUpdateNickname(params: PatchMeUpdateNicknameOperationRequestBody): Promise<PatchMeUpdateNicknameOperationResponseBody> {
      return await fetch('/me/update_nickname', {
        method: 'PATCH',
        body: params,
      })
    },

    async patchMeUpdateSettingsNotifications(params: PatchMeUpdateSettingsNotificationsOperationRequestBody): Promise<PatchMeUpdateSettingsNotificationsOperationResponseBody> {
      return await fetch('/me/update_settings_notifications', {
        method: 'PATCH',
        body: params,
      })
    },

    async patchMeUpdateHasSeenOnboardingSession(): Promise<PatchMeUpdateHasSeenOnboardingSessionOperationResponseBody> {
      return await fetch('/me/update_has_seen_onboarding_session', {
        method: 'PATCH',
      })
    },

    async patchMeConfirmLegalNotice(): Promise<PatchMeConfirmLegalNoticeOperationResponseBody> {
      return await fetch('/me/confirm_legal_notice', {
        method: 'PATCH',
      })
    },

    async getMeCourses(params: GetMeCoursesOperationParams): Promise<GetMeCoursesOperationOperationResponseBody> {
      return await fetch('/me/courses', {
        query: params,
      })
    },

    async getMeBundles(): Promise<GetMeBundlesOperationOperationResponseBody> {
    // add a dummy sleep to simulate a slow response
      return await fetch('/me/bundles')
    },

    async getMeBumpers(): Promise<GetMeBumpersOperationOperationResponseBody> {
      return await fetch('/me/bumpers')
    },

    async getMeSubscriptions(): Promise<GetMeSubscriptionsOperationOperationResponseBody> {
      return await fetch('/me/subscriptions')
    },

    async getMeSubscriptionId(id: number): Promise<GetMeSubscriptionIdOperationOperationResponseBody> {
      return await fetch(`/me/subscriptions/${id}`)
    },

    async postMeSubscriptionsIdAddCouponCode(id: number, body: PostMeSubscriptionsIdAddCouponCodeOperationRequestBody): Promise<void> {
      return await fetch(`/me/subscriptions/${id}/add_coupon_code`, {
        method: 'POST',
        body,
      })
    },

    async postMeSubscriptionIdCreateCustomerPortalSession(id: number): Promise<PostMeSubscriptionIdCreateCustomerPortalSessionOperationOperationResponseBody> {
      return await fetch(`/me/subscriptions/${id}/create_customer_portal_session`, {
        method: 'POST',
      })
    },

    async putMeSubscriptionsIdCancel(id: number): Promise<PutMeSubscriptionsIdCancelOperationOperationResponseBody> {
      return await fetch(`/me/subscriptions/${id}/cancel`, {
        method: 'PUT',
      })
    },
  }) as MeRepository
}
